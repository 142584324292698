import { useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { getLegacyConversions } from "../utils/api.js";
import {
  queryToJson,
  constructQueryParams,
  constructNewURL,
} from "../utils/helper.js";
import Loader from "../components/Loader";
import NotFound from "./NotFound";
import Trending from "./Trending";

export default function CheckLegacy() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInit, setIsInit] = useState(false);

  const navigate = useNavigate();

  const init = async () => {
    const payload = queryToJson(searchParams.entries());

    // Check if storyId tourId langId inside the payload
    if (!("type" in payload)) {
      if ("storyId" in payload || "langId" in payload || "tourId" in payload) {
        // Do conversion
        const result = await getLegacyConversions(
          constructQueryParams(payload)
        );
        console.log(result);

        // Negative to new url

        const newURL = constructNewURL(result);

        console.log(newURL);
        navigate(`/listen${newURL}`);
      }
    }

    setIsInit(true);
  };

  useEffect(() => {
    init();
  }, []);

  if (!isInit) return <Loader />;
  // Home
  return <Trending />;
}
