import StoriusLogoS from "../assets/logo_s.svg";

const CoverPicture = ({ img }) => {
  const renderImage = () => {
    if (img !== "") {
      return (
        <div className="drop-shadow-md flex justify-center items-center -z-10">
          <img alt="" src={img} className="w-full h-96 aspect-auto md:rounded-md object-cover" />
        </div>
      );
    } else {
      return (
        <div
          className={`drop-shadow-md -z-10 flex justify-center items-center w-screen h-80 md:w-96 md:h-96 md:rounded-md bg-gradient-to-br from-storius-orange to-storius-blue`}
        >
          <img alt="No media is found" src={StoriusLogoS} className="h-20 w-20" />
        </div>
      );
    }
  };

  return <>{renderImage()}</>;
};

export default CoverPicture;
