import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import StoryPlayer from "./pages/StoryPlayer";
import StoryTourPlayer from "./pages/StoryTourPlayer";
import TourPlaylist from "./pages/TourPlaylist";
import CheckLegacy from "./pages/CheckLegacy";
import NotFound from "./pages/NotFound";

export default function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<CheckLegacy />} />
          <Route exact path="/listen/tour/:id" element={<TourPlaylist />} />
          <Route
            exact
            path="/listen/tour/:id/:storyId"
            element={<StoryTourPlayer />}
          />
          <Route exact path="/listen/story/:id" element={<StoryPlayer />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}
