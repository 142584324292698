import { useSearchParams, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { listenStory, listenTour, getLanguages } from "../utils/api.js";
import { queryToJson, renderLocales } from "../utils/helper.js";
import Player from "../components/Player";
import TourPlayer from "../components/TourPlayer";
import Header from "../components/Header.js";
import NotFound from "./NotFound";
import Loader from "../components/Loader";

export default function StoryTourPlayer() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { id, storyId } = useParams();
  const [story, setStory] = useState("");
  const [tour, setTour] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const init = async () => {
    setIsLoading(true);

    try {
      // Get Locale
      const locale = searchParams.get("locale");

      // Check if storyId tourId langId inside the payload
      const [languages, story, tour] = await Promise.all([
        getLanguages(),
        listenStory(storyId, locale),
        listenTour(id, locale),
      ]);

      // Apply locale
      story.locale = renderLocales([story.locale], languages);
      story.locale = story.locale[0];
      story.otherLangs = renderLocales(story.otherLangs, languages);

      setStory(story);
      setTour(tour);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [id, storyId]);

  if (!isLoading) {
    // TODO: Check if storyId inside tour stories
    let isExisting = tour.stories.find((tstory) => tstory.id === story.id);

    if (isExisting) {
      return (
        <>
          <ToastContainer />
          <Header tour={tour} story={story} />
          <div className="pt-[72px] md:pt-24 flex justify-center mb-20">
            <Player tour={tour} story={story} />
          </div>
        </>
      );
    }
    return <NotFound />;
  } else {
    return <Loader />;
  }
}
