import React from "react";
import { ReactComponent as LoadingIcon } from "../assets/Loader.svg";

const Loader = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <LoadingIcon className="w-20 h-20 animate-spin fill-[#00c5c5] group-hover:fill-white" />
    </div>
  );
};

export default Loader;
