import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as StoriusLogo } from "../assets/logo.svg";
import LangSelect from "./LangSelect";
import StorySelect from "./StorySelect";

const Header = ({ story, tour }) => {
  return (
    <header className="fixed top-0 w-full z-10">
      <nav
        className="
          flex flex-nowrap
          items-center
          justify-between
          py-4
          px-4 sm:px-8
          text-lg text-gray-700
          bg-white
          drop-shadow
          sticky 
          top-0 
          h-18
        "
      >
        <Link className="my-2" to="/">
          <StoriusLogo className="h-6" />
        </Link>
        <div className="flex gap-5 justify-end">
          {/* {tour && story && <StorySelect tour={tour} story={story} />} */}
          {story && <LangSelect story={story} />}
        </div>
      </nav>
    </header>
  );
};

export default Header;
