import StoriusLogoS from "../assets/logo_s.svg";
import Message from "../components/Message";

const NotFound = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen w-screen">
        <div className="m-auto flex flex-col justify-center items-center space-y-10">
          <div
            className={`drop-shadow-md flex flex-col justify-center items-center w-screen h-screen bg-gradient-to-br from-storius-orange to-storius-blue`}
          >
            <img src={StoriusLogoS} className="h-20 w-20" />
            <h1 className="mt-10 font-bold text-white text-4xl">
              Page Not Found
            </h1>
          </div>
        </div>
        <div className="fixed z-10 bottom-0">
          <Message />
        </div>
      </div>
    </>
  );
};

export default NotFound;
