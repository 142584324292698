import { useState, useRef, useEffect } from "react";
import { useLocalStorage } from "@mantine/hooks";
import { Link } from "react-router-dom";

import { ReactComponent as FastForwardBtn } from "../assets/btn/10s_prev.svg";
import { ReactComponent as FastBackwardBtn } from "../assets/btn/10s_after.svg";
import { ReactComponent as Playbtn } from "../assets/btn/play_btn.svg";
import { ReactComponent as Pausebtn } from "../assets/btn/pause_btn.svg";
import { ReactComponent as NextBtn } from "../assets/btn/next_btn.svg";
import { ReactComponent as PreviousBtn } from "../assets/btn/previous_btn.svg";
import { ReactComponent as AutoplayBtn } from "../assets/btn/autoplay.svg";

import { fstm } from "../utils/helper";

const PlayerControl = ({ story, tour }) => {
  // Player
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);
  const [audioLength, setAudioLength] = useState(0);

  // Tour Story Player Only
  const [hasPrevious, setHasPrevious] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [previousStory, setPreviousStory] = useState("");
  const [nextStory, setNextStory] = useState("");

  // Autoplay Toggle
  const [autoplay, setAutoplay] = useLocalStorage({
    key: "autoplay",
    defaultValue: "true",
    getInitialValueInEffect: false,
  });

  // Refs
  const audioSrc = story.audioUrl;
  // const audioSrc = "testing.mp3"; // testing only
  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef();

  // Audio Playback Tracker
  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        // Autoplay
        // TODO: If autoplay is enabled
        if (autoplay === "true") {
          console.log("[Debug] Autoplay is enabled");
          // TODO: If there is a next story
          if (hasNext) {
            console.log("[Debug] Redirect to next story!");
            window.location.replace(nextStory);
          }
        } else {
          console.log("Audio Ends, set to zero");
          audioRef.current.currentTime = 0;
          setIsPlaying(false);
        }
      }
      setTrackProgress(audioRef.current.currentTime);
    }, [1000]);
  };

  // Pause Audio when switching to another page
  useEffect(() => {
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  // Handle Change Languages
  useEffect(() => {
    console.log("Change Language!");
    audioRef.current.pause();
    setIsPlaying(false);
    audioRef.current = new Audio(audioSrc);

    if (autoplay === "true") {
      // Play audio
      playAudio();
    }
  }, [story]);

  useEffect(() => {
    audioRef.current.addEventListener("loadedmetadata", (e) => {
      setAudioLength(e.target.duration);
    });
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  // Scrub the audio
  const onScrub = (value) => {
    console.log(value);
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(false);
    }
    startTimer();
  };

  // Player Audio Handler
  const fastForward = (second) => {
    const gotoTime = Math.max(0, second + trackProgress);
    onScrub(gotoTime);
    onScrubEnd();
  };

  // Player Audio Handler
  const playAudio = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      // Play the audio
      console.log("Play");
      // Start the timer to track
      startTimer();
    } else {
      console.log("Pause");
    }
  };

  const getTourStoryURLwithLang = (story) => {
    return `/listen/tour/${tour.id}/${story.id}?locale=${story.locale}`;
  };

  // Construct story url
  const getPreviousNextStoryUrl = () => {
    let currentStoryIdx = tour.stories.map((item) => item.id).indexOf(story.id);

    // DONE: Check tour length
    if (tour.stories.length === 1) {
      setHasPrevious(false);
      setHasNext(false);
    } else {
      if (currentStoryIdx === 0) {
        setHasPrevious(false);
        setHasNext(true);

        let storyUrl = getTourStoryURLwithLang(
          tour.stories[currentStoryIdx + 1]
        );
        setNextStory(storyUrl);
      } else if (currentStoryIdx === tour.stories.length - 1) {
        setHasPrevious(true);
        setHasNext(false);

        let storyUrl = getTourStoryURLwithLang(
          tour.stories[currentStoryIdx - 1]
        );
        setPreviousStory(storyUrl);
      } else {
        setHasPrevious(true);
        setHasNext(true);

        let previousUrl = getTourStoryURLwithLang(
          tour.stories[currentStoryIdx - 1]
        );
        let nextUrl = getTourStoryURLwithLang(
          tour.stories[currentStoryIdx + 1]
        );

        setPreviousStory(previousUrl);
        setNextStory(nextUrl);
      }
    }
  };

  useEffect(() => {
    if (tour && story) {
      getPreviousNextStoryUrl();
    }
  }, []);

  /**
   * renderPlayerButton
   * This function render player button base on the player state
   */
  const renderPlayerButton = () => {
    if (isPlaying) {
      // Playing currently, show pause button
      return (
        <Pausebtn className="h-12 w-12 transition ease-in-out hover:scale-110 duration-150" />
      );
    } else {
      // Pausing, show play button
      return (
        <Playbtn className="h-12 w-12 transition ease-in-out hover:scale-110 duration-150" />
      );
    }
  };

  /**
   * renderTranscriptButton
   * This function render transcript button or music button base on the state
   */
  const renderAudioDuration = () => {
    if (trackProgress) {
      return (
        <div className="mx-4 sm:mx-0 flex justify-between">
          <div className="text-sm text-gray-900">{fstm(trackProgress)}</div>
          <div className="text-sm text-gray-900">
            -{fstm(audioLength - trackProgress)}
          </div>
        </div>
      );
    }
  };

  let step = isNaN(audioLength / 100) ? "" : audioLength / 100;
  let max = isNaN(audioLength) ? "" : audioLength;

  return (
    <div className="bg-white">
      <div>
        <div className="flex justify-around items-center">
          <input
            type="range"
            value={trackProgress}
            step={step}
            min="0"
            max={max}
            className="
            form-range
            text-center rounded-full w-full bg-green-500
            focus:outline-none focus:ring-0 focus:shadow-none
            mx-4 sm:mx-0
          "
            onChange={(e) => onScrub(e.target.value)}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
          />
        </div>
        {renderAudioDuration()}
        <div className="mb-10 mt-5 flex justify-around items-center">
          {tour && (
            <>
              {hasPrevious && (
                <Link to={previousStory}>
                  <PreviousBtn className="h-5 w-5 fill-gray-800 transition ease-in-out hover:scale-110 duration-150" />
                </Link>
              )}
              {!hasPrevious && (
                <button>
                  <PreviousBtn className="h-5 w-5 fill-gray-300" />
                </button>
              )}
            </>
          )}

          <button
            onClick={() => {
              fastForward(-10);
            }}
          >
            <FastForwardBtn className="h-8 w-8 transition ease-in-out hover:scale-110 duration-150" />
          </button>
          <button
            onClick={() => {
              playAudio();
            }}
          >
            {renderPlayerButton()}
          </button>
          <button
            onClick={() => {
              fastForward(10);
            }}
          >
            <FastBackwardBtn className="h-8 w-8 transition ease-in-out hover:scale-110 duration-150" />
          </button>
          {tour && (
            <>
              {hasNext && (
                <Link to={nextStory}>
                  <NextBtn className="h-5 w-5 fill-gray-800 transition ease-in-out hover:scale-110 duration-150" />
                </Link>
              )}
              {!hasNext && (
                <button>
                  <NextBtn className="h-5 w-5 fill-gray-300" />
                </button>
              )}
              {autoplay === "true" && (
                <button
                  onClick={() => {
                    setAutoplay("false");
                  }}
                >
                  <AutoplayBtn className="h-5 w-5 stroke-emerald-500" />
                </button>
              )}
              {autoplay !== "true" && (
                <button
                  onClick={() => {
                    setAutoplay("true");
                  }}
                >
                  <AutoplayBtn className="h-5 w-5 stroke-gray-300" />
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerControl;
