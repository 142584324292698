import config from "../configs/config";

export async function getLegacyConversions(query) {
  const API_ENDPOINT = config.api_endpoint;

  // https://api.storius.mysgapp.co/prototypeIV/webplayer/getStoryById?id=001
  const response = await fetch(`${API_ENDPOINT}/legacy/${query}`);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return response.ok;
  }
}

export async function listenStory(id, locale = undefined) {
  const API_ENDPOINT = config.api_endpoint;

  // https://api.storius.mysgapp.co/prototypeIV/webplayer/getStoryById?id=001
  const response = await fetch(
    `${API_ENDPOINT}/listen/story/${id}?locale=${locale}`
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return response.ok;
  }
}

export async function listenTour(id, locale = undefined) {
  const API_ENDPOINT = config.api_endpoint;

  // https://api.storius.mysgapp.co/prototypeIV/webplayer/getStoryById?id=001
  const response = await fetch(
    `${API_ENDPOINT}/listen/tour/${id}?locale=${locale}`
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return response.ok;
  }
}

export async function getLanguages() {
  const API_ENDPOINT = config.api_endpoint;

  // https://api.storius.mysgapp.co/prototypeIV/webplayer/getStoryById?id=001
  const response = await fetch(`${API_ENDPOINT}/languages`);
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return response.ok;
  }
}

export async function getTrending() {
  const API_ENDPOINT = config.api_endpoint;

  // https://api.storius.mysgapp.co/prototypeIV/webplayer/getStoryById?id=001
  const response = await fetch(
    `${API_ENDPOINT}/recommendations/trending?limit=50`
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return response.ok;
  }
}

export async function getRecommended(id, locale) {
  const API_ENDPOINT = config.api_endpoint;

  // https://api.storius.mysgapp.co/prototypeIV/webplayer/getStoryById?id=001
  const response = await fetch(
    `${API_ENDPOINT}/recommendations/?id=${id}&locale=${locale}`
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return response.ok;
  }
}
