import { ReactComponent as AppLogo } from "../assets/app_icon.svg";
import { useState, useEffect } from "react";
import { useLocalStorage } from "@mantine/hooks";
import moment from "moment";
import { isMobile } from "react-device-detect";

// Download Link
// http://onelink.to/zte8zd

const Message = ({ story, tour }) => {
  const [local, setLocal] = useLocalStorage({
    key: "msgExpDT",
    defaultValue: null,
    getInitialValueInEffect: false,
  });

  // DONE: Check if expired
  const isExpired = () => {
    if (local === null || local === "") {
      return true;
    }
    const closeDT = moment(local);
    const now = moment();
    return closeDT.isBefore(now);
  };

  let [isOpen, setIsOpen] = useState(false);

  const closedMessage = () => {
    // DONE: Set Close DT
    setLocal(moment().add(1, "minutes").format());
    setIsOpen(false);
  };

  const renderBtn = () => {
    if (isMobile) {
      if (story) {
        // story
        // window.open(
        //   `https://link.storiusapp.com/?link=https://link.storiusapp.com/?type%3Dstory%26id%3D${story.id}%26locale%3D${story.locale.value}&apn=com.storius.storiusapp&isi=1581928786&ibi=com.storius.storiusapp`,
        //   "_self"
        // );
        return (
          <a
            href={`https://link.storiusapp.com/?link=https://link.storiusapp.com/?type%3Dstory%26id%3D${story.id}%26locale%3D${story.locale.value}&apn=com.storius.storiusapp&isi=1581928786&ibi=com.storius.storiusapp`}
            className="h-6 rounded-full flex justify-center items-center px-2 py-1.5 bg-storius-orange text-white font-bold text-[10px]"
          >
            <span>Open in app</span>
          </a>
        );
      } else {
        if (tour) {
          // tour
          // window.open(
          //   `https://link.storiusapp.com/?link=https://link.storiusapp.com/?type%3Dtour%26id%3D${tour.id}%26locale%3D${tour.locale}&apn=com.storius.storiusapp&isi=1581928786&ibi=com.storius.storiusapp`,
          //   "_self"
          // );
          return (
            <a
              href={`https://link.storiusapp.com/?link=https://link.storiusapp.com/?type%3Dtour%26id%3D${tour.id}%26locale%3D${tour.locale}&apn=com.storius.storiusapp&isi=1581928786&ibi=com.storius.storiusapp`}
              className="h-6 rounded-full flex justify-center items-center px-2 py-1.5 bg-storius-orange text-white font-bold text-[10px]"
            >
              <span>Open in app</span>
            </a>
          );
        }
      }
    }

    // Fallback
    return (
      <a
        href="https://storiusapp.com/download/"
        target="_blank"
        className="h-6 rounded-full flex justify-center items-center px-2 py-1.5 bg-storius-orange text-white font-bold text-[10px]"
      >
        <span>Get the app</span>
      </a>
    );
  };

  useEffect(() => {
    if (isExpired()) {
      setIsOpen(true);
    }
  }, []);

  if (isOpen) {
    return (
      <>
        <div className="h-20 text-gray-800 px-4 py-2 w-96 drop-shadow-xl rounded-t-[20px] relative backdrop-blur-md flex items-center">
          <span className="inline-block mr-auto align-middle">
            <AppLogo className="h-12" />
          </span>
          <span className="inline-block align-middle mr-auto">
            <p className="font-bold text-[12px]">Faster & Better Experience</p>
            <p className="font-normal text-[10px]">
              Listen to local stories at east
            </p>
          </span>
          {renderBtn()}
          <button
            onClick={() => {
              closedMessage();
            }}
            className="bg-transparent text-2xl font-semibold leading-none outline-none focus:outline-none mx-auto"
          >
            <span>×</span>
          </button>
        </div>
      </>
    );
  }
};

export default Message;
