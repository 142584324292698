import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import Loader from "../components/Loader";
import { getTrending } from "../utils/api.js";
import { Link } from "react-router-dom";

const Trending = () => {
  const [trending, setTrending] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const init = async () => {
    try {
      const data = await getTrending();
      console.log(data);
      setTrending(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (isLoading) return <Loader />;
  return (
    <>
      <Header />
      <div className="pt-[72px] md:pt-24 flex justify-center mb-20">
        <div className="w-full px-8 sm:px-0 sm:w-3/4 md:w-1/2 lg:w-1/3">
          <h2 className="mt-10 sm:mt-0 text-4xl font-extrabold mb-10">
            Trending Stories/Tours
          </h2>
          <ul role="list" className="grid grid-cols-1 gap-y-8">
            {trending.map((item) => (
              <li key={item.id} className="relative">
                <div className="h-96 w-full group block aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 shadow-lg overflow-hidden">
                  <img
                    src={item.coverImg}
                    alt={item.title}
                    className="w-full h-full object-cover pointer-events-none group-hover:opacity-75"
                  />
                  <Link
                    to={`/listen/${item.type}/${item.id}`}
                    className="absolute inset-0 focus:outline-none"
                  >
                    <span className="sr-only">{item.title}</span>
                  </Link>
                </div>
                <p className="mt-2 block text-base font-medium text-gray-900 truncate pointer-events-none">
                  {item.title}
                </p>
                <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                  {item.owner.username}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Trending;
