import { useSearchParams, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { listenTour, getLanguages } from "../utils/api.js";
import { renderLocales } from "../utils/helper.js";
import TourPlayer from "../components/TourPlayer";
import Header from "../components/Header.js";
import NotFound from "./NotFound";
import Loader from "../components/Loader";

export default function TourPlaylist() {
  let [searchParams] = useSearchParams();
  const { id } = useParams();

  const [tour, setTour] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const init = async () => {
    setIsLoading(true);

    try {
      // Get Locale (if any)
      const locale = searchParams.get("locale");

      // Check if storyId tourId langId inside the payload
      const [languages, tour] = await Promise.all([
        getLanguages(),
        listenTour(id, locale),
      ]);

      // Apply Locales
      tour.stories.forEach((story) => {
        story.otherLangs = renderLocales(story.otherLangs, languages);
      });

      setTour(tour);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [searchParams, id]);

  if (!isLoading) {
    if (tour) {
      // Tour Player
      return (
        <>
          <ToastContainer />
          <Header tour={tour} />
          <div className="pt-[72px] md:pt-24 flex justify-center mb-20">
            <TourPlayer tour={tour} />
          </div>
        </>
      );
    } else {
      return <NotFound />;
    }
  } else {
    return <Loader />;
  }
}
